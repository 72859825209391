<template>
  <div class="menu">
    <div v-if="isMobile()">
      <b-link to="/">
        <img id="mobileLogo" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2023/03/websitelogo_white_stickynav.png" />
      </b-link>
      <b-modal
        id="modal-1"
        ref="myModal"
        hide-footer
        hide-header
        centered
        v-show="show"
        @show-me="show = true"
        @hide-me="show = false"
      >
        <b-row>
          <b-col>
            <h2>
              Download The Alps Tour App 
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <a
              :href="config.VUE_APP_GOOGLE_PLAY"
              target="_blank"
              rel="noopener"
            >
              <b-img
                class="appStore"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-4.png"
              ></b-img>
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <a
              :href="config.VUE_APP_APPLE_STORE"
              target="_blank"
              rel="noopener"
            >
              <b-img
                class="appStore"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-5.png"
              ></b-img>
            </a>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div id="nav">
      <b-navbar type="dark" toggleable="xl" id="topNav" align="center">
        <b-navbar-toggle
          class="ml-auto"
          type="dark"
          target="nav-collapse"
        ></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/schedule">Schedule</b-nav-item>
          <b-nav-item-dropdown
            id="my-nav-dropdown"
            text="Players"
            toggle-class="nav-link-custom"
          >
            <b-dropdown-item to="/players">Player Profiles</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/playerarea"
              >Players Area, Entries & Exemptions</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item
              target="_blank"
              href="https://ocs-ffg.com/ffg-members/"
              >Secure Member’s Area</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Media"
          >
            <b-dropdown-item to="/news">News</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/video">Video</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/stats">Stats</b-nav-item>
          <b-nav-item
            :to="{
              name: 'contact',
              query: {
                id: 16,
              },
            }"
            >About Us</b-nav-item
          >
          <!--     <b-nav-item-dropdown
            id="my-nav-dropdown"
            text="About Us"
            toggle-class="nav-link-custom"
          >
            <b-dropdown-item
              :to="{
                name: 'contact',
                query: {
                  id: 16,
                },
              }"
              >About Us</b-dropdown-item
            >
          </b-nav-item-dropdown> -->
          <b-nav-item
            :to="{
              name: 'contact',
              query: {
                id: 18,
              },
            }"
            >Contact Us</b-nav-item
          >
          <b-nav-item v-if="allow_qschool == 'B' || allow_qschool == 'W'" to="/q-school">Q-School</b-nav-item>
          <div style="display:inline-flex" class="searchMobile">
            <b-form-input
              v-model="text"
              @keyup.enter="goSearch()"
              placeholder="Search"
            ></b-form-input>
            <b-button
              :to="{
                name: 'search',
                query: { search: text },
              }"
              class="sIcon"
            >
              <font-awesome-icon :icon="['fa', 'search']" />
            </b-button>
          </div>
        </b-collapse>
      </b-navbar>
    </div>
    <div>
      <div class="logoWrap">
        <b-nav-item to="/">
            <img id="logo" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/03/websitelogo.jpeg" />
        </b-nav-item>
      </div>
    </div>
    <div id="socialNav">
      <div>
        <b-nav class="search" align="left">
          <b-nav-item
            class="menuS"
            :href="
              'https://www.facebook.com/' +
                config.VUE_APP_FACEBOOK_NAME
            "
            target="_blank"
            rel="noopener"
          >
            <font-awesome-icon
              id="fb"
              class="icon"
              :icon="['fab', 'facebook-square']"
            />
          </b-nav-item>
          <b-nav-item
            class="menuS"
            :href="
              'https://www.instagram.com/' + config.VUE_APP_INSTA_NAME + '/'
            "
            target="_blank"
            rel="noopener"
          >
            <font-awesome-icon
              id="insta"
              class="icon fa-gradient"
              :icon="['fab', 'instagram-square']"
            />
          </b-nav-item>
          <b-nav-item
            class="menuS"
            :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME"
            target="_blank"
            rel="noopener"
          >
            <font-awesome-icon
              id="twit"
              class="icon"
              :icon="['fab', 'twitter-square']"
            />
          </b-nav-item>
          <b-nav-item
            class="menuS"
            :href="
              'https://www.youtube.com/channel/' + config.VUE_APP_YOUTUBE_ID
            "
            target="_blank"
            rel="noopener"
          >
            <font-awesome-icon
              id="yt"
              class="icon"
              :icon="['fab', 'youtube-square']"
            />
          </b-nav-item>
        </b-nav>
      </div>
      <div style="display:inline-flex">
        <b-form-input
          v-model="text"
          @keyup.enter="goSearch()"
          placeholder="Search"
        ></b-form-input>
        <b-button
          :to="{
            name: 'search',
            query: { search: text },
          }"
          class="sIcon"
        >
          <font-awesome-icon :icon="['fa', 'search']" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "Menu",
  props: ["config"],
  data() {
    return {
      menueLink: "menuLink",
      text: "",
      showModal: false,
      allow_qschool:""
    };
  },
  created() {
    apiCall
    .tmParamsBase()
    .then(({ data }) => {
      this.allow_qschool = data.tm_params.allow_qschool;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      sessionStorage.setItem("showModal", "yes");
    } else {
      sessionStorage.setItem("showModal", "no");
    }
  },
  mounted() {
    const showModal = sessionStorage.getItem("showModal");
    console.log(showModal);
    if (showModal === "yes") setTimeout(() => this.$refs.myModal.show(), 3500);
  },
  methods: {
    goSearch() {
      this.$router.push({ name: "search", query: { search: this.text } });
    },
    show() {
      this.showModal = true;
      this.$emit("show-me");
    },
    close() {
      this.showModal = false;
      sessionStorage.setItem("showModal", "no");
      this.$emit("hide-me");
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    extension: function (extension){
      var I = 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/03/websitelogo.jpeg';
      // var P = 'https://ocs-ffg.com/pmws-images/ffg/logos/websitelogo.png';
      if(I.includes('.jpg')){
        extension = 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/03/websitelogo.jpeg';
      }
      else {
        extension = 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/03/websitelogo.jpeg';
      }
      return extension
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mobileLogo {
  position: absolute;
  z-index: 99;
  /* margin-top: 13px; */
  width: 250px;
  max-width: 510px;
  left: 0;
  right: 0;
  margin: auto;
  top: 15px;
}
::v-deep tr:nth-child(even) {
  background-color: #dfe1e6;
}
#socialNav {
  position: absolute;
  top: 90px;
  right: 30px;
  width: 250px;
}

::v-deep .navbar {
  padding: 0px;
}

li.nav-item {
  display: inline-block;
}
::v-deep .nav-link {
  color: white !important;
  padding: 0.7em 1rem;
}

::v-deep .navbar {
  padding-bottom: 0;
}

::v-deep .navbar-toggler {
  border: 0px;
}

::v-deep .nav-link:hover {
  color: #263056 !important;
  background-color: white;
}
.menuS > .nav-link:hover {
  background-color: transparent !important;
}
.menuS > .nav-link {
  padding: 0;
}
.menuS {
  padding: 0 2px;
}
::v-deep .dropdown-item {
  color: white !important;
  background-color: #263056;
}

::v-deep .dropdown-menu {
  color: white !important;
  background-color: #263056;
}

::v-deep .dropdown-item:hover {
  color: #263056 !important;
  background-color: white;
}

#logo {
  max-width: 510px;
  padding-top: 1%;
}
.logoWrap {
  text-align: center;
}
.icon {
  font-size: 25pt;
}

#fb {
  color: #3b5998;
}

.fa-gradient {
  color: #4c68d7;
}

#twit {
  color: #1da1f2;
}

#yt {
  color: #ff0000;
}

#topNav {
  background-color: #263056;
}

.nav-link {
  color: white;
}

.menu {
  padding-bottom: 0%;
}

.router-link-exact-active {
  background-color: white;
  color: #263056 !important;
}
.sIcon {
  color: white !important;
  background-color: #263056;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
::v-deep .modal-content {
  border-radius: 17px;
}
::v-deep .modal-body {
  text-align: center;
  background-color: #263056;
  color: white;
  border-radius: 15px;
}
.appStore {
  padding: 20px;
}
.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
::v-deep .dropdown-toggle::after {
  vertical-align: 0.2em;
  font-size: 15px;
}
.navbar-collapse {
  text-align: center;
}
::v-deep .navbar-toggler > span {
  cursor: pointer;
  border-radius: 0px;
  height: 2px;
  width: 35px;
  background: white;
  position: absolute;
  display: block;
  content: "";
  right: 20px;
  transition: all 200ms ease-in-out;
}
::v-deep .not-collapsed > span {
  background-color: transparent;
}
::v-deep .not-collapsed  > span::before {
transform: rotate(-45deg);
    top: 0!important;
}
::v-deep .not-collapsed  > span::after {
    transform: rotate(45deg);
        top: 0;
}
::v-deep .navbar-toggler > span::before {
  cursor: pointer;
  border-radius: 0px;
  height: 2px;
  width: 35px;
  background: white;
  position: absolute;
  display: block;
  content: "";
  top: -10px;
  transition: all 200ms ease-in-out;
}
::v-deep .navbar-toggler > span::after {
  cursor: pointer;
  border-radius: 0px;
  height: 2px;
  width: 35px;
  background: white;
  position: absolute;
  display: block;
  content: "";
  bottom: -10px;
  transition: all 200ms ease-in-out;
}

@media only screen and (min-width: 1200px) {
  .searchMobile {
    display: none!important;
  }
}

@media only screen and (max-width: 1199px) {
  .searchMobile {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 80%;
  }
  #socialNav {
    display: none;
  }
  .navbar-toggler {
    padding: 40px;
  }
  .navbar-collapse>.nav-item{
    display: flow-root;
  }
  ::v-deep .dropdown-menu {
    position: relative!important;
    top: 100%;
    left: 0;
    z-index: 1000;
    /* display: none; */
    float: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: center;
    list-style: none;
    background-color: #1d2441;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    /* width: 260px; */
    margin: auto;
  }
  ::v-deep .dropdown-item {
    color: white !important;
    background-color: #1d2441;
  }
}

@media only screen and (max-width: 980px) {
  .logoWrap {
    text-align: left;
  }
}
@media only screen and (max-width: 840px) {
  .logoWrap {
    text-align: center;
  }
}
@media only screen and (min-width: 760px) {
  #mobileLogo {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  #logo {
    width: 60%;
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  #nav-collapse {
    padding-left: 0;
  }
  #socialNav {
    position: inherit;
    width: 100%;
    padding: 20px;
  }
  ::v-deep .dropdown-item {
    font-size: 13px;
  }
}
@media only screen and (min-width: 1200px) {
  ::v-deep .navbar-expand-xl .navbar-collapse {
    display: inline-table !important;
  }
}
</style>
