import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props:true
  },
  {
    path: "/schedule",
    name: "schedule",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule.vue")
  },
  {
    path: "/reports",
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reports.vue"),
    props: true
  },
  // report qsc
  {
    path: "/reportsqsc",
    name: "reportsqsc",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reportsqsc.vue"),
    props: true
  },
  {
  path: "/live",
  name: "live",
  component: () =>
    import(/* webpackChunkName: "about" */ "../views/live.vue"),
  },
  {
    path: "/players",
    name: "players",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/players.vue")
  },
  {
    path: "/playerprofile/:refno",
    name: "playerprofile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/playerprofile.vue"),
    props: true
  },
  {
    path: "/playerarea",
    name: "playerArea",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/playerarea.vue")
  },
  {
    path: "/news",
    name: "news",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/news.vue")
  },
  {
    path: "/news/:id",
    name: "news story",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  {
    path: "/privacy-policy/",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/video",
    name: "video",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/video.vue")
  },
  {
    path: "/stats",
    name: "stats",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/stats.vue")
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contact.vue"),
      props:true
  },
  {
    path: "/contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contact.vue"),
      props:true
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contact.vue"),
      props:true
  },
  {
    path: "/q-school",
    name: "q-school",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/qschool.vue")
  },
  {
    path: "/tournament/:course_code/:id",
    name: "tournament",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tournament.vue"),
    props: true
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/search.vue"),
    props: true
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'NotFound'
  }
];

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;
