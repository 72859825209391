var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tab',{staticClass:"topTab",attrs:{"title":this.TourName + ' - ' + _vm.title}},[_c('div',[_c('ReportTable',{attrs:{"data":_vm.data,"title":_vm.title,"home":true,"updown":_vm.updown,"config":_vm.config,"team":_vm.teamEvent,"match":_vm.match}}),_c('b-nav',{staticClass:"fullListings",attrs:{"small":""}},[_c('b-container',{staticStyle:{"display":"contents"}},[(_vm.title === 'Tournament Entries')?_c('b-nav-item',{staticClass:"Flisting-btn right",attrs:{"to":{
            name: 'reports',
            query: {
              url:
                _vm.config.VUE_APP_PLAYER_HOST +
                '/tic/tmentry.cgi?tourn=' +
                _vm.courseCode +
                '~season=' +
                _vm.Year +
                '~alphaorder~',
              id: _vm.Year,
              code: _vm.courseCode,
              title: 'Tournament Entries',
            },
          }}},[_c('span',{staticClass:"black"}),_vm._v(" Full Listing ")]):_vm._e(),(_vm.title === 'Final Result')?_c('b-nav-item',{staticClass:"Flisting-btn right",attrs:{"to":{
            name: 'reports',
            query: {
              url:
                _vm.config.VUE_APP_PLAYER_HOST +
                '/tic/tmresult.cgi?tourn=' +
                _vm.courseCode +
                '~season=' +
                _vm.Year +
                'result=PF~&',
              id: _vm.Year,
              code: _vm.courseCode,
              title: 'Final Result',
            },
          }}},[_c('span',{staticClass:"black"}),_vm._v(" Full Listing ")]):_vm._e(),(_vm.currentReport.includes('draw'))?_c('b-nav-item',{staticClass:"left drawListing-btn",attrs:{"to":{
            name: 'reports',
            query: {
              url: _vm.reportURL,
              id: _vm.Year,
              code: _vm.courseCode,
              title: 'Draw',
            },
          }}},[_c('span',{staticClass:"black"}),_vm._v(" Draw ")]):_vm._e(),(_vm.currentReport.includes('score'))?_c('b-nav-item',{staticClass:"right four Flisting-btn",attrs:{"to":{
            name: 'reports',
            query: {
              url: _vm.reportURL,
              id: _vm.Year,
              code: _vm.courseCode,
              title: _vm.title,
            },
          }}},[_c('span',{staticClass:"black"}),_vm._v(" Full Listing ")]):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }