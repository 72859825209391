var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{attrs:{"id":"nav"}},[_c('b-navbar',{attrs:{"type":"dark","toggleable":"xl","id":"topNav","align":"center"}},[_c('b-nav',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-nav-item',{attrs:{"to":"/"}},[_vm._v("Home")]),_c('b-nav-item',{attrs:{"to":"/schedule"}},[_vm._v("Schedule")]),_c('b-nav-item-dropdown',{attrs:{"id":"my-nav-dropdown","text":"Players","toggle-class":"nav-link-custom"}},[_c('b-dropdown-item',{attrs:{"to":"/players"}},[_vm._v("Player Profiles")]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":"/playerarea"}},[_vm._v("Players Area, Entries & Exemptions")]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"target":"_blank","href":"https://ocs-ffg.com/ffg-members/"}},[_vm._v("Secure Member’s Area")])],1),_c('b-nav-item-dropdown',{attrs:{"id":"my-nav-dropdown","text":"Media","toggle-class":"nav-link-custom"}},[_c('b-dropdown-item',{attrs:{"to":"/news"}},[_vm._v("News")]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":"/video"}},[_vm._v("Video")])],1),_c('b-nav-item',{attrs:{"to":"/stats"}},[_vm._v("Stats")]),_c('b-nav-item',{attrs:{"to":{
              name: 'contact',
              query: {
                id: 16,
              },
            }}},[_vm._v("About Us")]),_c('b-nav-item',{attrs:{"to":{
            name: 'contact',
            query: {
              id: 18,
            },
          }}},[_vm._v("Contact Us")])],1)],1)],1),_c('b-row',{class:_vm.logoRow},_vm._l((_vm.footerData),function(logos,index){return _c('b-col',{key:index},[_c('a',{attrs:{"href":logos.acf.sponsor_url,"target":"_blank","rel":"noopener"}},[_c('b-img',{class:_vm.logoImg,attrs:{"src":logos.acf.image}})],1)])}),1),_c('b-row',{class:_vm.logoRowMobile},_vm._l((_vm.footerData.slice(0, 3)),function(logos,index){return _c('b-col',{key:index},[_c('a',{attrs:{"href":logos.acf.sponsor_url,"target":"_blank","rel":"noopener"}},[_c('b-img',{class:_vm.logoImg,attrs:{"src":logos.acf.image}})],1)])}),1),_c('b-row',{class:_vm.logoRowMobile},_vm._l((_vm.footerData.slice(3, 6)),function(logos,index){return _c('b-col',{key:index},[_c('a',{attrs:{"href":logos.acf.sponsor_url,"target":"_blank","rel":"noopener"}},[_c('b-img',{class:_vm.logoImg,attrs:{"src":logos.acf.image}})],1)])}),1),_c('b-row',{class:_vm.logoRowMobile},_vm._l((_vm.footerData.slice(6, 9)),function(logos,index){return _c('b-col',{key:index},[_c('a',{attrs:{"href":logos.acf.sponsor_url,"target":"_blank","rel":"noopener"}},[_c('b-img',{class:_vm.logoImg,attrs:{"src":logos.acf.image}})],1)])}),1),_c('b-row',{class:_vm.logoRowMobile},_vm._l((_vm.footerData.slice(9, 12)),function(logos,index){return _c('b-col',{key:index,attrs:{"cols":"4"}},[_c('a',{attrs:{"href":logos.acf.sponsor_url,"target":"_blank","rel":"noopener"}},[_c('b-img',{class:_vm.logoImg,attrs:{"src":logos.acf.image}})],1)])}),1),_c('b-row',{staticClass:"ocs"},[_c('b-col',[_c('a',{attrs:{"href":"https://ocs-sport.com","target":"_blank","rel":"noopener"}},[_c('b-img',{class:_vm.ocsLogo,attrs:{"src":"https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2021/08/OCS__Sport_Logo_2017_WO.png"}})],1)]),_c('b-col',[_c('p',{staticClass:"tmark"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Alps Tour Golf ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }