<template>
  <div class="tableHome">
    <b-tabs>
      <HomeTabOne
        :Year="Year"
        :courseCode="courseCode"
        :TourName="TourName"
        :currentReport="currentReport"
        :currentTitle='currentTitle'
        :reportURL="reportURL"
        :live='live'
        :config="config"
        :match='match'
        :roundsPLayed="roundsPLayed"
      />
      <Oom :Year="Year" :config="config"/>
    </b-tabs>
  </div>
</template> 

<script>
import Oom from "@/components/oom.vue";
import HomeTabOne from "@/components/homeTabOne.vue";

export default {
  name: "tableHome",
  props: ["Year", "courseCode", "TourName", "currentReport", "reportURL", 'live', 'config','match', 'currentTitle','roundsPLayed'],
  components: {
    Oom,
    HomeTabOne
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .nav-link.active {
  color: white !important;
  background-color: #263056 !important;
  width: 100%;
  border-color: #263056 !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 18pt;
  font-weight: 500;
}

::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
.tableHome {
  padding-bottom: 2%;
}
.mt {
  margin-top: 0 !important;
}
::v-deep .nav-tabs{
      border-bottom: 0e6;
}
@media only screen and (max-width: 480px) {
 ::v-deep .nav-link{
    font-size: 1rem;
  }
}
</style>
