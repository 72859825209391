<template>
  <div>
    <div id="slider" v-if="slidesTotal > 0">
      <VueSlickCarousel
        :autoplay="true"
        :infinite="true"
        :speed="1000"
        :autoplaySpeed="6000"
        ref="c1"
        :asNavFor="$refs.c2"
        :focusOnSelect="true"
      >
        <div
          v-for="(upComingTours, index) in FutureEventFilter.slice(0, 5)"
          :key="index"
          class="backImg"
        >
          <b-img
            :src="
              upComingTours.course_images === 'Y'
                ? DEFIMAGESAMS3 + 'ffg/courses/' +
                  Year +
                  '/' +
                  upComingTours.course_code +
                  '/' +
                  upComingTours.course_image_file_1
                : config.VUE_APP_DEFAULT_COURSE
            "
            class="courseImg backImg"
          ></b-img>
          <b-row>
            <b-col>
              <div class="cap">
                <h3>
                  {{ upComingTours.short_name }}
                </h3>
                <p>
                  {{ upComingTours.course }}
                </p>
                <p>
                  {{ upComingTours.dates }}
                </p>
                <a :href="'/tournament/' + upComingTours.code + '/' + Year">
                  <p class="moreInfo-btn tournlink">
                    More Tour Info
                  </p>
                </a>
                  <img class="logo sponLogo" :src="upComingTours.sponsor_logo" />
                  <!-- <img class="logo sponLogo" :src="upComingTours.sponsor_logo.replace('http://alps.ocs-ffg.com/', DEFIMAGESAMS3 + 'ffg' + '/').replace('https://alps.ocs-ffg.com/', DEFIMAGESAMS3 + 'ffg' + '/')" /> -->
              </div>
            </b-col>
          </b-row>
        </div>
      </VueSlickCarousel>
      <VueSlickCarousel
        ref="c2"
        :asNavFor="$refs.c1"
        :slidesToShow="parseInt(slidesTotal)"
        :focusOnSelect="true"
        class="bslide"
      >
        <div
          v-for="(upComingTours, index) in FutureEventFilter.slice(0, 10)"
          :key="index"
          class="minibackImg"
        >
          <b-img
            :src="
              upComingTours.course_images === 'Y'
                ? DEFIMAGESAMS3 + 'ffg/courses/' +
                  Year +
                  '/' +
                  upComingTours.course_code +
                  '/' +
                  upComingTours.course_image_file_1
                : config.VUE_APP_DEFAULT_COURSE
            "
            class="courseImg minibackImg"
          ></b-img>
          <b-row>
            <b-col>
              <p class="tourName">
                {{ upComingTours.short_name }}
                <br />
                <span class="tourNameTwo">{{ upComingTours.course }}</span>

                {{ upComingTours.dates }}
              </p>
            </b-col>
          </b-row>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="mobile" v-if="slidesTotal > 0">
      <b-carousel
        id="carousel-1"
        :interval="4000"
        controls
        img-width="1024"
        img-height="480"
      >
        <b-carousel-slide
          v-for="(upComingTours, index) in FutureEventFilter.slice(0, 5)"
          :key="index"
          class="mobCap"
          :img-src="
            upComingTours.course_images === 'Y'
              ? DEFIMAGESAMS3 + 'ffg/courses/' +
                Year +
                '/' +
                upComingTours.course_code +
                '/' +
                upComingTours.course_image_file_1
              : config.VUE_APP_DEFAULT_COURSE
          "
          >
            <a :href="'/tournament/' + upComingTours.code + '/' + Year">
              <span>
                {{ upComingTours.short_name }}
                <br />
                <span>{{ upComingTours.course }}</span>

                {{ upComingTours.dates }}
              </span></a
            >
          </b-carousel-slide
        >
      </b-carousel>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "slider",
  components: { VueSlickCarousel },
  props: ["Year", "config", "DEFIMAGESAMS3"],
  data() {
    return {
      slide: 0,
      sliding: false,
      imgURL: this.config.VUE_APP_COURSE_URL,
      file: "File_1.jpg",
      upComingTours: [],
      img: this.config.VUE_APP_DEFAULT_COURSE,
    };
  },
  computed: {
    FutureEventFilter: function() {
      return this.upComingTours.filter(
        (upComingTours) => upComingTours.days_away >= 0
      );
    },
  slidesTotal:function(){
    return this.FutureEventFilter.length
  },
},
  methods: {
    replaceByDefault(e) {
      e.target.src = this.img;
    },
  },
    created() {
    apiCall
      .upComing(this.Year)
      .then(({ data }) => {
        this.upComingTours = data.tournaments.tournaments_entry;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep .bslide > .slick-list > .slick-track > .slick-slide {
  width: 100%!important;
  display: contents;
}
.sponLogo {
  /* background: #fff; */
  /* padding: 40px; */
  /* margin-right: 20px; */
}
p.moreInfo-btn.tournlink {
  background-color: #263056;
  width: 200px;
  text-align: center;
  color: #fff;
  /* border-radius: 10px; */
  padding: 0.5rem 1rem;
}
::v-deep .bslide > .slick-list > .slick-track > .slick-slide {
  width: 100%!important;
}
::v-deep .bslide > .slick-list > .slick-track{
  display: flex;
  overflow: scroll;
  width: 100%!important;
  height: inherit;
}
::v-deep a {
  color: black;
}
img.logo {
  max-width: 136px;
  float: right;
  padding-right: 45px;
  margin-top: -100px;
}

.courseImg {
  width: 100%;
}
::v-deep .slick-slider > button {
  display: none !important;
}
.backImg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 415px !important;
}
.minibackImg {
  background-color: lightgrey;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 76px;
}
::v-deep .slick-slide > div > div > img {
  opacity: 0.5;
}
::v-deep .slick-current > div > div > img {
  opacity: 1;
}
.cap {
  position: absolute;
  color: white;
  padding-left: 4%;
  text-align: left;
  color: #ffffff;
  background-color: rgba(38, 48, 86, 0.7);
  top: -300px;
  padding-top: 20px;
  height: 225px;
  left: 15px;
  right: 58%;
}
::v-deep .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  transform: translateZ(0);
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
}
::v-deep .bslide > .slick-list {
  height: 150px;
}

.tourName {
  font-size: 14px;
  color: #263056;
  margin-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.tourNameTwo {
  color: #d3d3d3;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 1281px) {
  img.logo {
    max-width: 136px;
    float: right;
    padding-right: 45px;
    margin-top: -100px;
  }
}
@media only screen and (max-width: 900px) {
  #slider {
    display: none;
  }
  .mobile {
    display: block;
    padding: 20px 0;
  }
  ::v-deep .mobCap > .carousel-caption {
    bottom: 0px;
    background-color: rgba(38, 48, 86, 0.7);
    margin: auto;
  }
  ::v-deep .img-fluid {
    height: 180px;
  }
  ::v-deep .mobCap > .carousel-caption > a{
    color:#fff!important;
  }
}
@media only screen and (max-width: 480px) {
  ::v-deep .img-fluid {
    height: 130px;
  }
  ::v-deep .mobCap > .carousel-caption{
    bottom:10px;
  }
}
</style>
