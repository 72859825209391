<template>
  <div>
      <b-tab :title="currentOom.full_name + ' Order of Merit'"> 
        <b-table
          :items="ooms.oom.oom_entry"
          :fields="fields"
          :per-page="pPage"
        >
          <template #cell(nationality)="row">
            <span>
              <img
                :class="flag"
                :src="
                  (config.VUE_APP_FLAG_URL +
                    row.item.nationality)
                    | lowercase
                "
              />
            </span>
          </template>
        <template #cell(name)="row">
          <a :href="'/playerprofile/' + row.item.playerno">
            {{ row.item.forename }} {{ row.item.surname }}
            <span v-if="row.item.pro_ind === 'Am'">(a)</span>
          </a>
        </template>
        </b-table>
        <div class="more">
          <div class='flex'>
        <b-button @click="upDown" class='moreBtn'><span v-if="pPage === 4">More</span><span v-if="pPage === 10">Less</span></b-button><b-nav-item to="/stats" class='fullList'><span class='black'>| </span> Full Listing</b-nav-item>
          </div>
        </div>
      </b-tab>
  </div>
</template>
<script>
import apiCall from "@/services/api.js";

export default {
  name: "oom",
  props: ["Year", 'config'],

  data() {
    return {
      fields: [
        { key: "pos", label: "Pos" , class: "posnum"},
        { key: "name", label: "Name" },
        { key: "nationality", label: "Nat" },
        { key: "oom_value", label: "Points" },
        { key: "tournaments_played", label: "Tourns" }
      ],
      currentOom: [],
      previousOom: [],
      year: parseInt(this.Year) - 1,
      flag: "flag",
      pPage:4
    };
  },
  methods:{
    upDown(){
      if (this.pPage === 4)
      this.pPage = 10
      else if (this.pPage === 10)
      this.pPage = 4
    }
  },
  computed:{
    ooms:function (){
      if (this.currentOom !== null)
      return this.currentOom
      else return this.previousOom
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    }
  },
  async mounted() {
    try {
      var stat = "-ooms-oom-" + process.env.VUE_APP_OOM
      // First call, await for call to return
      let second_call = await apiCall.stat(this.Year, stat);
      this.currentOom = second_call.data;
      this.loading = false;
      // Third call, await for call to return
      let third_call = await apiCall.stat(this.year, stat);
      this.previousOom = third_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
::v-deep tbody {
  font-size: 1rem;
}
a {
  color: #337ab7!important;
  background-color: transparent;
  font-weight: 500;
}
a:hover{
  text-decoration: underline;
}
::v-deep td.posnum {
  padding: 5px;
  background-color: #263056;
  color: #ffffff;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  text-align: center;
}
::v-deep thead {
  color: white; 
}

::v-deep th {
  width: 15%;
  text-align: left;
  background-color: #263056!important;
  font-weight: 400;
}

::v-deep td {
  text-align: left;
  padding-top: 6px!important;
  padding-bottom: 6px!important;
  font-size: 1rem;
}
::v-deep th:first-child{
  text-align: center;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
::v-deep tr:nth-child(odd) {
  background-color: #dfe1e6;
}
.table{
      margin-bottom: 0;
}
.more{
  background-color: #dfe1e6;
  text-align: center;
}
::v-deep td{
  border-top: 0!important;
}
.flex{
  display: inline-flex;
}
.moreBtn{
  color: #337ab7 !important;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #dfe1e6!important;
  border: none!important;
  box-shadow: none!important;
  padding: 0.375rem 0.175rem;
}
.fullList{
  width: auto!important;
}
.fullList>a{
  color: #337ab7 !important;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}
li {
  list-style: none;
}
.black{
  color: black;
  margin-left: -10px;
}

@media only screen and (max-width: 768px) {

  ::v-deep th {
    font-size: 13px !important;
  }
  ::v-deep td {
    font-size: 13px !important;
  }
.fullList>a{
  font-size: 12px!important;
  padding-left: 0.7rem;
}.moreBtn{
  font-size: 12px!important;
}
::v-deep th:nth-child(3){
  display: none;
}
::v-deep td:nth-child(3){
  display: none;
}
}
</style>
