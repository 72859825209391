<template>
  <div class="footer">
    <div id="nav">
      <b-navbar type="dark" toggleable="xl" id="topNav" align="center">
        <b-nav id="nav-collapse" is-nav>
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/schedule">Schedule</b-nav-item>
          <b-nav-item-dropdown
            id="my-nav-dropdown"
            text="Players"
            toggle-class="nav-link-custom"
          >
            <b-dropdown-item to="/players">Player Profiles</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/playerarea"
              >Players Area, Entries & Exemptions</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item
              target="_blank"
              href="https://ocs-ffg.com/ffg-members/"
              >Secure Member’s Area</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            id="my-nav-dropdown"
            text="Media"
            toggle-class="nav-link-custom"
          >
            <b-dropdown-item to="/news">News</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/video">Video</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/stats">Stats</b-nav-item>
          <b-nav-item :to="{
                name: 'contact',
                query: {
                  id: 16,
                },
              }">About Us</b-nav-item>
    <!--      <b-nav-item-dropdown
            id="my-nav-dropdown"
            text="About Us"
            toggle-class="nav-link-custom"
          >
            <b-dropdown-item :to="{
                name: 'contact',
                query: {
                  id: 16,
                },
              }">About Us</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item>e-Shop</b-dropdown-item>
          </b-nav-item-dropdown> -->
          <b-nav-item :to="{
              name: 'contact',
              query: {
                id: 18,
              },
            }">Contact Us</b-nav-item>
           <!-- <b-nav-item href="https://wp-alpstour.ocs-sport.com/ffg-online-entries-2021/">Q-School</b-nav-item> -->
        </b-nav>
      </b-navbar>
    </div>
    <b-row :class="logoRow">
      <b-col v-for="(logos, index) in footerData" :key="index">
        <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
          <b-img :class="logoImg" :src="logos.acf.image" />
        </a>
      </b-col>
    </b-row>
    <b-row :class="logoRowMobile">
      <b-col v-for="(logos, index) in footerData.slice(0, 3)" :key="index">
        <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
          <b-img :class="logoImg" :src="logos.acf.image" />
        </a>
      </b-col>
    </b-row>
    <b-row :class="logoRowMobile">
      <b-col v-for="(logos, index) in footerData.slice(3, 6)" :key="index">
        <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
          <b-img :class="logoImg" :src="logos.acf.image" />
        </a>
      </b-col>
    </b-row>
    <b-row :class="logoRowMobile">
      <b-col v-for="(logos, index) in footerData.slice(6, 9)" :key="index">
        <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
          <b-img :class="logoImg" :src="logos.acf.image" />
        </a>
      </b-col>
    </b-row>
    <b-row :class="logoRowMobile">
      <b-col cols='4' v-for="(logos, index) in footerData.slice(9, 12)" :key="index">
        <a :href="logos.acf.sponsor_url" target="_blank" rel="noopener">
          <b-img :class="logoImg" :src="logos.acf.image" />
        </a>
      </b-col>
    </b-row>
    <b-row class="ocs">
      <b-col>
        <a href="https://ocs-sport.com" target="_blank" rel="noopener">
          <b-img
            :class="ocsLogo"
            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2021/08/OCS__Sport_Logo_2017_WO.png"
        /></a>
      </b-col>
      <b-col>
        <p class='tmark'>
          © {{new Date().getFullYear()}} Alps Tour Golf 
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "Footer",
  props:['config'],
  data() {
    return {
      footerData: [],
      logoImg: "logoImg",
      ocsLogo: "ocsLogo",
      logoRow: "logoRow",
      logoRowMobile: "logoRowMobile", 
    };
  },
      created() {
    const json = "wp/v2/sponsors/";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.footerData = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.footer {
  background-color: #263056;
  color: white;
  padding-top: 2%;
  padding-left: 2%;

}

.logoRowMobile {
  display: none;
}

.logoImg {
  width: 80%;
}

.ocsLogo {
  width: 100px;
  padding-bottom: 4%;
}

.logoRow {
  padding-top: 4%;
  padding-bottom: 4%;
  text-align: center;
  margin-right: 0;
}
.ocs {
  margin-right: 0;
}
.tmark{
  float: right;
  margin-top: 50px;
}
::v-deep .nav-link {
  color: white !important;
  padding: 0.7rem 1rem;
  font-size: 14px;
}

::v-deep .navbar {
  padding-bottom: 0;
}

::v-deep .navbar-toggler {
  border: 0px;
}

::v-deep .nav-link:hover {
  color: #263056 !important;
  background-color: white;
}

::v-deep .dropdown-item {
  color: white !important;
  background-color: #263056;
}

::v-deep .dropdown-menu {
  color: white !important;
  background-color: #263056;
}

::v-deep .dropdown-item:hover {
  color: #263056 !important;
  background-color: white;
}
@media only screen and (max-width: 768px) {
  .ocsLogo {
    width: 30%;
  }
}
@media only screen and (max-width: 480px) {
  .logoImg {
    width: 100%;
  }
  ::v-deep .ocsLogo {
    width: 20%!important;
    margin-top: 40px;
  }
  .logoRow {
    display: none;
  }
  .logoRowMobile {
    display: flex;
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
  .ocs {
    margin-right: 0;
    margin-left: 0;
  }
  #nav{
    display: none;
  }
  .ocsLogo {
  width: 50%;
  }
}
</style>
