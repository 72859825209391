var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.slidesTotal > 0)?_c('div',{attrs:{"id":"slider"}},[_c('VueSlickCarousel',{ref:"c1",attrs:{"autoplay":true,"infinite":true,"speed":1000,"autoplaySpeed":6000,"asNavFor":_vm.$refs.c2,"focusOnSelect":true}},_vm._l((_vm.FutureEventFilter.slice(0, 5)),function(upComingTours,index){return _c('div',{key:index,staticClass:"backImg"},[_c('b-img',{staticClass:"courseImg backImg",attrs:{"src":upComingTours.course_images === 'Y'
              ? _vm.DEFIMAGESAMS3 + 'ffg/courses/' +
                _vm.Year +
                '/' +
                upComingTours.course_code +
                '/' +
                upComingTours.course_image_file_1
              : _vm.config.VUE_APP_DEFAULT_COURSE}}),_c('b-row',[_c('b-col',[_c('div',{staticClass:"cap"},[_c('h3',[_vm._v(" "+_vm._s(upComingTours.short_name)+" ")]),_c('p',[_vm._v(" "+_vm._s(upComingTours.course)+" ")]),_c('p',[_vm._v(" "+_vm._s(upComingTours.dates)+" ")]),_c('a',{attrs:{"href":'/tournament/' + upComingTours.code + '/' + _vm.Year}},[_c('p',{staticClass:"moreInfo-btn tournlink"},[_vm._v(" More Tour Info ")])]),_c('img',{staticClass:"logo sponLogo",attrs:{"src":upComingTours.sponsor_logo}})])])],1)],1)}),0),_c('VueSlickCarousel',{ref:"c2",staticClass:"bslide",attrs:{"asNavFor":_vm.$refs.c1,"slidesToShow":parseInt(_vm.slidesTotal),"focusOnSelect":true}},_vm._l((_vm.FutureEventFilter.slice(0, 10)),function(upComingTours,index){return _c('div',{key:index,staticClass:"minibackImg"},[_c('b-img',{staticClass:"courseImg minibackImg",attrs:{"src":upComingTours.course_images === 'Y'
              ? _vm.DEFIMAGESAMS3 + 'ffg/courses/' +
                _vm.Year +
                '/' +
                upComingTours.course_code +
                '/' +
                upComingTours.course_image_file_1
              : _vm.config.VUE_APP_DEFAULT_COURSE}}),_c('b-row',[_c('b-col',[_c('p',{staticClass:"tourName"},[_vm._v(" "+_vm._s(upComingTours.short_name)+" "),_c('br'),_c('span',{staticClass:"tourNameTwo"},[_vm._v(_vm._s(upComingTours.course))]),_vm._v(" "+_vm._s(upComingTours.dates)+" ")])])],1)],1)}),0)],1):_vm._e(),(_vm.slidesTotal > 0)?_c('div',{staticClass:"mobile"},[_c('b-carousel',{attrs:{"id":"carousel-1","interval":4000,"controls":"","img-width":"1024","img-height":"480"}},_vm._l((_vm.FutureEventFilter.slice(0, 5)),function(upComingTours,index){return _c('b-carousel-slide',{key:index,staticClass:"mobCap",attrs:{"img-src":upComingTours.course_images === 'Y'
            ? _vm.DEFIMAGESAMS3 + 'ffg/courses/' +
              _vm.Year +
              '/' +
              upComingTours.course_code +
              '/' +
              upComingTours.course_image_file_1
            : _vm.config.VUE_APP_DEFAULT_COURSE}},[_c('a',{attrs:{"href":'/tournament/' + upComingTours.code + '/' + _vm.Year}},[_c('span',[_vm._v(" "+_vm._s(upComingTours.short_name)+" "),_c('br'),_c('span',[_vm._v(_vm._s(upComingTours.course))]),_vm._v(" "+_vm._s(upComingTours.dates)+" ")])])])}),1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }