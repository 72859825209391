<template>
  <div>
    <b-row :class="postsRow">
      <b-col
        id="mobilePost"
        v-for="(posts, index) in posts.slice(0, 3)"
        :key="index"
      >
        <div class="overflowImage">
          <b-img
            :class="postImg"
            :src="
              config.VUE_APP_WP_DIGITAL_OCEAN +
                posts.better_featured_image.media_details.file
            "
            fluid
            alt="Responsive image"
          />
        </div>
        <h1 class="postsTitle">
          <span v-html="posts.title.rendered"></span>
        </h1>
        <span class="postText" v-html="posts.excerpt.rendered"></span>
        <b-nav-item :to="'/news/' + posts.id">
          Full Story
        </b-nav-item>
      </b-col>
    </b-row>
    <div>
      <b-nav-item class='moreNews' to="/news">
        <font-awesome-icon id="leftMore" class="icon" :icon="['fa', 'chevron-right']" /> More
        Alps Tour Golf News
      </b-nav-item>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "posts",
  props: ["config"],
  data() {
    return {
      posts: [],
      postsRow: "posts",
      postImg: "postImg",
    };
  },
        created() {
    const json = "wp/v2/posts";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.posts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
span.postText {
  font-size: 12pt;
}
h1.postsTitle {
  font-size: 20pt;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflowImage {
  overflow: hidden;
  height: 278px;
}
#mobilePost:last-child {
  /* padding-right: 0px; */
}

.postRow {
  padding-bottom: 10px;
}
::v-deep .nav-link {
  color: white !important;
  background-color: #263056;
  width: 100%;
  text-align: center;
}
.moreNews>a{
  background-color: transparent;
  color: #263056!important;
}
#leftMore{
  color: #fff;
  background-color: #263056;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  padding: 3px;
}
@media only screen and (max-width: 480px) {
  ::v-deep .nav-link {
    width: 100%;
  }
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
}
@media only screen and (max-width: 1165px) {
  #mobilePost:last-child {
    padding-right: 15px;
    margin-top: 2em;
  }
  div#mobilePost {
    margin-top: 1em;
  }
}
</style>
