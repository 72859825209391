<template>
  <b-tab :title="this.TourName + ' - ' + title" class="topTab">
    <div>
      <ReportTable
        :data="data"
        :title="title"
        :home="true"
        :updown="updown"
        :config="config"
        :team='teamEvent'
        :match='match'
      />
      <b-nav small class="fullListings">
        <b-container style="display: contents;">
          <b-nav-item
            v-if="title === 'Tournament Entries'"
            class="Flisting-btn right"
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_PLAYER_HOST +
                  '/tic/tmentry.cgi?tourn=' +
                  courseCode +
                  '~season=' +
                  Year +
                  '~alphaorder~',
                id: Year,
                code: courseCode,
                title: 'Tournament Entries',
              },
            }"
            ><span class="black"></span> Full Listing
          </b-nav-item
          >

          <b-nav-item
            v-if="title === 'Final Result'"
            class="Flisting-btn right"
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_PLAYER_HOST +
                  '/tic/tmresult.cgi?tourn=' +
                  courseCode +
                  '~season=' +
                  Year +
                  'result=PF~&',
                id: Year,
                code: courseCode,
                title: 'Final Result',
              },
            }"
            ><span class="black"></span> Full Listing
            </b-nav-item
          >


          <b-nav-item
            v-if="currentReport.includes('draw')"
            class="left drawListing-btn"
            :to="{
              name: 'reports',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: 'Draw',
              },
            }"
            ><span class="black"></span> Draw
          </b-nav-item>
          <!-- <b-nav-item
            v-if="title === 'Draw'"
            class="right drawListing-btn "
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_PLAYER_HOST +
                  '/tic/tmentry.cgi?tourn=' +
                  courseCode +
                  '~season=' +
                  Year +
                  '~alphaorder~',
                id: Year,
                code: courseCode,
                title: 'Tournament Entries',
              },
            }"
          >
            <span class="black">|</span> Full Listing
          </b-nav-item> -->

          <!-- <b-nav-item
            v-if="title === 'Score'"
            class="center three Flisting-btnt"
            :to="{
              name: 'reports',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: 'Score',
              },
            }"
            ><span class="black">|</span> Score
          </b-nav-item>
          <b-nav-item
            v-if="title === 'Score'"
            class="right Flisting-btnt three"
            :to="{
              name: 'reports',
              query: {
                url:
                  config.VUE_APP_PLAYER_HOST +
                  '/tic/tmentry.cgi?tourn=' +
                  courseCode +
                  '~season=' +
                  Year +
                  '~alphaorder~',
                id: Year,
                code: courseCode,
                title: 'Tournament Entries',
              },
            }"
            ><span class="black">|</span> Full Listing
          </b-nav-item> -->
          <!-- <b-nav-item
            v-if="title === 'Live'"
            class="center four Flisting-btn"
            :to="{
              name: 'live',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: 'Hole By Hole',
              },
            }"
            ><span class="black">|</span> Hole By Hole
          </b-nav-item> -->
          <b-nav-item
            v-if="currentReport.includes('score')"
            class="right four Flisting-btn"
            :to="{
              name: 'reports',
              query: {
                url: reportURL,
                id: Year,
                code: courseCode,
                title: title,
              },
            }"
            ><span class="black"></span> Full Listing
          </b-nav-item>
        </b-container>
      </b-nav>
    </div>
  </b-tab>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "homeTourOne",
  components: {
    ReportTable,
  },
  props: [
    "Year",
    "courseCode",
    "TourName",
    "currentReport",
    "currentTitle",
    "reportURL",
    "live",
    "config",
    'match',
    'roundsPLayed'
  ],

  data() {
    return {
      updown: false,
      fields: [
        { isActive: true, key: "serial_no", label: "No." },
        { isActive: true, key: "playing_name", label: "Player Name" },
        { isActive: true, key: "", label: "Nat." },
        { isActive: true, key: "status_text", label: "Entry Status" },
        { isActive: true, key: "player_profile", label: "" },
      ],
      tableRow: "tableRow",
      data: [],
      tab: "tab",
      cell: "cell",
    };
  },
  computed: {
    liveOn: function(message) {
      if (this.live === "Y") message = " - Livescoring is on";
      else message = " - " + this.title;
      return message;
    },
    title: function(title) {
      if (this.currentReport.includes("entry")) title = "Tournament Entries";
      else if (this.currentReport.includes("draw")) 
      if (this.roundsPLayed == '0') {
        title = "Round " + '1' + " Draw";
      } else {
       title = "Round " + this.roundsPLayed + " Draw"; 
      }
      else if (this.currentReport.includes("latest")) title = "Round " + this.roundsPLayed +  " Scores Only";
      else if (this.currentReport.includes("scores")) title = "Round " + this.roundsPLayed +  " Scores Only";
      else if (this.currentReport.includes("scoreboard")) title = "Round " + this.roundsPLayed +  " Scoreboard";
      else if (this.currentReport.includes("result")) title = "Final Result";

      return title;
    },
        teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
  },
  methods: {
    updateStuff: function() {
      this.callOne()
    },
    upDown() {
      this.updown = !this.updown;
    },
        callOne() {
      apiCall
        .report(this.currentReport)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
  created() {
    setInterval(this.updateStuff, 30000);
  },
  mounted() {
    this.callOne()
  },
};
</script>

<style scoped>
::v-deep .title {
    display: none;
}
::v-deep div#entries {
    height: 400px;
    display: block;
    overflow: scroll;
}
::v-deep td.drawName.odd {
  /* width: 30%; */
}
::v-deep .entries .LeftAlign {
  text-align: left;
  padding-left: 20px!important;
}
::v-deep .entries th:first-child {
  text-align: center;
  display: none;
}
::v-deep .entries td.posnum {
  padding: 5px;
  /* padding-top: 4px; */
  padding-top: 7px;
  padding-bottom: 7px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  color: #000;
  font-weight: bold;
  display: none;
}
::v-deep li.drawListing-btn > a {
  color: #337ab7 !important;
  padding-right: 10px;
  font-size: 20px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep li.drawListing-btn > a:hover{
  text-decoration: underline;
}
::v-deep li.drawListing-btn {
  width: auto !important;
}
::v-deep .cell {
  padding-top: 6px;
  padding-bottom: 6px;
}
.Flisting-btn {
  width: auto !important;
}
.Flisting-btn-more {
  color: #337ab7 !important;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #dfe1e6 !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.375rem 0.175rem;
}
.Flisting-btn-more:hover {
  border: none;
  text-decoration: underline;
}
li.Flisting-btn > a {
  color: #337ab7 !important;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
li.Flisting-btn > a:hover {
text-decoration: underline;
}
ul.nav.fullListings.small {
  background: #dfe1e6;
  text-align: center;
  display: inline-flex;
  width: 100%;
  justify-content: center;
}
.tableRow {
  width: 100%;
}
.link {
  text-align: center;
}
.nav-link {
  padding: 0;
  font-weight: 400 !important;
}
.nav-item {
  padding: 0.375rem 0.175rem;
}
.fields {
  color: white;
  background-color: #263056;
}
.topTab {
  padding: 0;
}
.black {
  color: black;
}

@media only screen and (max-width: 768px) {
  .Flisting-btn > a {
    font-size: 12px !important;
  }
  .Flisting-btn-more {
    font-size: 12px !important;
  }
}
</style>