<template>
  <div id="reportTable">
    <div class="title">
      <h3 v-if="home === false">{{ data.full_name }} - {{ title }}</h3>
      <p>
        <span v-if="home === false">
          {{ data.course_dates }}
        </span>
        <span v-else class="homeCourse">
          {{ data.course_dates }}
        </span>
        <span v-if="tablePick === 'Score' || tablePick === 'Live'">
          <br />
          Last Real Time Update - {{ localTime }}
          <span v-if="tablePick === 'Score' || tablePick === 'Live'">{{
            data.last_scores_update_date
          }}</span
          >{{ data.course_city }} -
          <span v-if="tablePick === 'Score' || tablePick === 'Live'"
            >{{ data.last_scores_update_time }}
            {{ data.last_scores_update_date }}</span
          ></span
        >
        <br />
        <span v-if="data.pft_time !== '' && tablePick === 'Live'">
          Projected Finish Time - {{ data.pft_time }}
        </span>
      </p>
      <p
        v-if="
          tablePick === 'Live' ||
            (tablePick === 'Score' && data.live_scoring === 'Y')
        "
        style="text-align: right;"
      >
        *Did not start from 1st Hole.
      </p>
    </div>
    <table>
      <thead></thead>
      <tbody>
        <template v-if="tablePick === 'Entries'">
          <Entries
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :updown="updown"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Draw'">
          <Draw
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :updown="updown"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Score' || tablePick === 'Live'">
          <Score
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="match"
            :updown="updown"
            :showGender="showGender"
            :represents_ind="represents_ind"
            :represents_heading="represents_heading"
            :represents_flag_type="represents_flag_type"
            :is_qualifier="is_qualifier"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Final'">
          <Results
            :data="data"
            :dataShowOOM="data.show_winnings"
            :dataHavePoints="data.have_points"
            :dataShowPointsName="data.show_points_name"
            :dataHaveOom="data.have_oom"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="match"
            :updown="updown"
            :showGender="showGender"
            :is_qualifier="is_qualifier"
            :represents_ind="represents_ind"
            :represents_heading="represents_heading"
            :represents_flag_type="represents_flag_type"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>

        <!-- <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
          </tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :playerData="playerData"
                :data="data"
                :config="config"
                :round="data.round"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
              />
            </td>
          </tr>
        </template> -->
        <template>
          <div class="LiveScoreMess" v-if="data.live_scoring_mess">
            <p class="LiveScoreMessPara">{{ data.live_scoring_mess }}</p>
          </div>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  Entries,
  Draw,
  Score,
  Results
} from "@ocs-software/vue-ocs";
export default {
  name: "reportTable",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "updown",
    "config",
    "match",
    "showGender",
    "Stableford",
    "represents_ind",
    "represents_heading",
    "represents_flag_type",
    "showFav",
    "is_qualifier",
    "team",
    "show_info_web",
    "hide_score_card"
  ],
  components: { Entries, Draw, Score, Results },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: []
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index)
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(playerNo) {
      var playerData = {};
      console.log("playerData");
      console.log(playerData);
      var cardID = playerNo;
      console.log("cardID");
      console.log(cardID);
      for (var i of this.data.scores.scores_entry) {
        console.log("in scores");
        if (i.member_no == playerNo) {
          this.playerData = i;
          console.log("playerData 384");
          console.log(this.playerData);
          break;
        }
      }
    }
  },
  computed: {
    localTime: function(local) {
      var today = new Date();
      if (this.tablePick === "Final") {
        local = today.getHours() + ":" + today.getMinutes();
      } else {
        local =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
      }
      return local;
    },
    tablePick: function(picked) {
      if (this.title.includes("Tournament Entries")) picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        (this.title.includes("Scores") && this.data.live_scoring === "N") ||
        (this.title.includes("Scoreboard") && this.data.live_scoring === "N")
      )
        picked = "Score";
      else if (
        (this.title.includes("Scores") && this.data.live_scoring === "Y") ||
        (this.title.includes("Scoreboard") && this.data.live_scoring === "Y")
      )
        picked = "Live";
      else if (this.title.includes("Final")) picked = "Final";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    ud(way) {
      if (this.updown === false) way = 4;
      else way = 10;
      return way;
    },
    Eds(reportData) {
      var ud = this.ud;
      if (this.home === false) {
        if (this.title.includes("Tournament Entries"))
          reportData = this.data.entries.entries_entry;
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry;
        else if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry;
        else if (this.title.includes("Final"))
          reportData = this.data.result.result_entry;
        return reportData;
      } else {
        if (this.title === "Tournament Entries")
          reportData = this.data.entries.entries_entry.slice(0, ud);
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry.slice(0, ud);
        else if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry.slice(0, ud);
        else if (this.title.includes("Final"))
          reportData = this.data.result.result_entry.slice(0, ud);
        return reportData;
      }
    }
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>

<style scoped>
::v-deep .title {
    /* display: none; */
}
::v-deep tbody {
  font-size: 1rem;
}
.homeCourse {
  margin-top: 20px;
  display: none;
}
p.LiveScoreMessPara {
  color: #fff;
  padding-top: 15px;
}
.LiveScoreMess {
  background: red;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
}
.title {
  text-align: center;
  background-color: #263056;
  color: white;
}
.catD {
  text-align: center;
  background-color: #263056;
  color: white;
}

.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #263056;
  color: white !important;
  font-weight: 400;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:nth-child(2) {
  width: 34%;
  padding: 6px;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.even {
  background-color: white !important;
}
.odd {
  background-color: #dfe1e6 !important;
}
tr:nth-child(odd) {
  background-color: #dfe1e6;
}
tr:nth-child(even) {
  background-color: #fff;
}
td {
  font-weight: 500;
}

td.posnum {
  padding: 5px;
  background-color: #263056;
  color: #ffffff;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  background-color: #263056;
  color: #ffffff;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
@media only screen and (max-width: 768px) {
  .title > h3 {
    font-size: 13px !important;
  }
  .title > p {
    font-size: 12px !important;
  }
  th {
    font-size: 13px !important;
  }
  td {
    font-size: 13px !important;
  }
}
svg.userIcon.svg-inline--fa.fa-user.fa-w-14 {
  color: #007cba;
}
</style>
<style scoped src="@/css/entries.css"></style>
<style scoped src="@/css/draw.css"></style>
<style src="@/css/scoresOnly.css"></style>
<style src="@/css/teamscoresOnly.css"></style>
<style src="@/css/hbh.css"></style>
<style src="@/css/results.css"></style>
<style src="@/css/orginalReportCard.css"></style>
<style src="@/css/playerReportCard.css"></style>